
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import promotionsApi from "@/core/services/PromotionsApi";
import membersApi from "@/core/services/MembersApi";
import { ElMessageBox, ElMessage } from "element-plus";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";
import VueHtml2pdf from "vue3-html2pdf";
import Nomination from "./docs/Nomination.vue";
import moment from "moment";

export default defineComponent({
    name: "Promotions",
    components: { Filters, FilterTags, VueHtml2pdf, Nomination },
    data() {
        return {
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: false,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            relevantYear: "",
            loading: true,
            promotionSearch: "",
            periodFrom: "",
            periodTo: "",
            selectedValue: "",
            filterGradeSearch: "",
            promotions: [] as any,
            currentPage: 1,
            promotionsCount: 0,
            totalPages: 0,
            selectedPromotions: [] as any,
            actualFilter: "",
            actualCol: "",
            promotionsTh: [
                { label: "ID", class: "min-w-60px", colName: "id" },
                { label: "Name", class: "min-w-60px", colName: "lastname" },
                {
                    label: "Firstname",
                    class: "min-w-60px",
                    colName: "firstname",
                },
                {
                    label: "Current grade",
                    class: "min-w-60px",
                    colName: "current_grade",
                },
                {
                    label: "Requested grade",
                    class: "min-w-60px",
                    colName: "induction",
                },
                {
                    label: "Bailliage",
                    class: "min-w-60px",
                    colName: "bailliage",
                },
                { label: "Amount", class: "min-w-60px", colName: "amount" },
                { label: "Date", class: "min-w-60px", colName: "date" },
            ],
            perm_pos: "",
            progressDialog: false,
            nominationValues: [] as any,
            nominationKey: 0,
            percentage: 0,
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs("Promotions", []);
        });

        return { resultsPerPage, router, debounce: createDebounce() };
    },
    mounted() {
        this.loading = false;
        this.getPromotions();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getPromotions();
        },
        downloadPDF(pdf: string, id?: number) {
            this.progressDialog = true;

            const preparePromotion = new Promise((resolve, reject) => {
                if (pdf === "promotion") {
                    this.nominationValues = [];
                    membersApi.getSpecificMember(id).then((res: any) => {
                        this.nominationValues.push({
                            type: "Promotion",
                            title: res.data.title ? res.data.title.label : "",
                            firstname: res.data.userprofile.firstname,
                            lastname: res.data.userprofile.lastname,
                            desc_before_grade:
                                "Nous avons l’honneur de vous faire savoir qu’après examen de votre dossier, nous avons décidé de vous promouvoir au grade de",
                            desc_after_grade:
                                "Suivant notre règlement, vous recevrez officiellement, lors d’un prochain Chapitre de votre choix, les insignes de votre nouveau grade.",
                            politeness:
                                "Nous sommes heureux de vous adresser nos vives félicitations et nos salutations les plus confraternelles.",
                            grade: res.data.grade ? res.data.grade : "-",
                            bailliage: {
                                national: res.data.userprofile
                                    .national_bailliage
                                    ? res.data.userprofile.national_bailliage
                                          .label
                                    : "",
                                regional: res.data.userprofile
                                    .regional_bailliage
                                    ? res.data.userprofile.regional_bailliage
                                          .label
                                    : "",
                            },
                            city: "Paris",
                            date: moment().locale("fr").format("D MMMM YYYY"),
                            omgd: false,
                        });
                        setTimeout(() => {
                            resolve(this.nominationValues);
                        }, 3000);
                    });
                }
            });

            switch (pdf) {
                case "promotion":
                    preparePromotion.then((response) => {
                        this.nominationKey++;
                        (this.$refs.promotion as any).generatePdf();
                    });
                    break;
            }
        },
        setProgress(e: any) {
            this.percentage = e;

            if (this.percentage === 100) {
                setTimeout(() => {
                    this.progressDialog = false;
                    this.percentage = 0;
                }, 1000);
            }
        },
        applyFilters() {
            var closeFilters = document.getElementById("filter") as any;
            closeFilters.click();
            this.getPromotions();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        editPromotion(memberId: any, promotionId: any, newTab?: boolean) {
            if (newTab) {
                const routeData = this.router.resolve({
                    name: "member-details",
                    params: {
                        editPromotionId: promotionId,
                        member_id: memberId,
                    },
                });
                window.open(routeData.href, "_blank");
            } else {
                this.router.push({
                    name: "member-details",
                    params: {
                        editPromotionId: promotionId,
                        member_id: memberId,
                    },
                });
            }
        },
        deletePromotion(promotionId?: any) {
            var promotionIdToDelete = [] as any;

            // If single delete
            if (promotionId) {
                promotionIdToDelete.push(promotionId);
                this.loading = true;
                promotionsApi.deletePromotions(promotionIdToDelete).then(() => {
                    this.selectedPromotions = [];
                    var topCheckbox = document.getElementById(
                        "topCheckbox"
                    ) as any;
                    topCheckbox.checked = false;
                    this.currentPage = 1;
                    this.loading = false;
                    ElMessage({
                        type: "info",
                        message: "Promotion deleted.",
                    });
                    this.getPromotions();
                });
                // Else if multiple delete
            } else {
                promotionIdToDelete = this.selectedPromotions;
                ElMessageBox.confirm(
                    "You have requested the deletion of multiple promotions.<br>Would you like to confirm this choice?",
                    "Confirmation",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        customClass: "custom-modal",
                        cancelButtonClass: "cancel-modal",
                        dangerouslyUseHTMLString: true,
                    }
                )
                    .then(() => {
                        this.loading = true;
                        promotionsApi
                            .deletePromotions(promotionIdToDelete)
                            .then(() => {
                                this.selectedPromotions = [];
                                // If we delete all the entries on the last page, we need to go back to the previous page
                                if (
                                    this.currentPage === this.totalPages &&
                                    this.selectedPromotions.length ===
                                        this.promotions.length
                                ) {
                                    this.currentPage = this.currentPage - 1;
                                }
                                var topCheckbox = document.getElementById(
                                    "topCheckbox"
                                ) as any;
                                topCheckbox.checked = false;
                                this.currentPage = 1;
                                this.loading = false;
                                ElMessage({
                                    type: "info",
                                    message: "Promotions deleted.",
                                });
                                this.getPromotions();
                            });
                    })
                    .catch(() => {
                        return; // Cancel
                    });
            }
        },
        getPromotions() {
            this.loading = true;
            this.selectedPromotions = [];
            this.promotions = [];
            var payload;

            // On check s'il y a des filtres
            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                        column: this.actualCol,
                        order: this.actualFilter,
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        filters: this.filtersPayload,
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                };
            }

            if (this.promotionSearch) {
                payload = { ...payload, query: this.promotionSearch };
            }

            promotionsApi.getPromotions(payload).then((res) => {
                res.data.promotion_list.map((promotion: any) => {
                    this.promotions.push(promotion);
                });
                this.promotionsCount = res.data.promotion_count;
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getPromotions();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedPromotions = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getPromotions();
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName(
                    "custom-checkbox-promotions"
                ) as any
            );

            this.selectedPromotions = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.promotions.map((promotion: any) => {
                    this.selectedPromotions.push(promotion.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedPromotions = [];
            }
        },
        handleActionSelect(promotion: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox" + promotion.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedPromotions.push(promotion.id);
            } else {
                this.selectedPromotions.map((promotion: any) => {
                    if (promotion.id === promotion.id) {
                        this.selectedPromotions.splice(
                            this.selectedPromotions.indexOf(promotion),
                            1
                        );
                    }
                });
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
            this.getPromotions();
        },
        queryChanged(query: string) {
            this.promotionSearch = query;
            this.getPromotions();
        },
    },
});
